<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  BellIcon,
  BellOffIcon,
} from "vue-feather-icons";
// import { Slide } from "vue-carousel";
// import { Carousel } from "vue-carousel";
// import { VueTyper } from "vue-typer";
// import Multiselect from "vue-multiselect";
import IndexService from "@/MainServices/IndexService.js";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import GeneralService from "@/MainServices/GeneralService.js";
// import Skeleton from "@/view/front/components/skeleton";

/**
 * page-index-home component
 */
export default {
  data() {
    return {
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      token:
        localStorage.getItem("token") !== null
          ? `Bearer ${localStorage.getItem("token")}`
          : null,
      data: [],
      search_text: null,
      selected_cities: null,
      cities: [],
      originalData: [],
      show_load_more: true,
      page_num: 1,
      sectorsData: [],
      featureData: [],
      spotlightData: [],
      featureCompaniesData: [],
      descriptionSearch: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    // Carousel,
    // Slide,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    ArrowUpIcon,
    // "vue-typer": VueTyper,
    // Multiselect,
    // Skeleton,
    BellIcon,
    BellOffIcon,
  },
  mounted() {
    IndexService.getCities().then((res) => {
      res.data.map((el) => {
        this.cities.push({
          id: el.id,
          value: el.city_name,
        });
      });
      setTimeout(() => {
        document.querySelector(".temp1").textContent =
          document.querySelector(".temp").textContent.slice(0, 100) + "...";
        document.querySelector(".temp2").textContent =
          document.querySelector(".temp3").textContent.slice(0, 100) + "...";
      }, 1000);
    });
    this.getSectors();
    this.getFeatureJobs();
    this.getSpotlightJobs();
    this.getFeatureCompanies();
  },
  methods: {
    _descriptionSearch(event) {
      this.descriptionSearch = event.target.checked;
    },
    getSectors() {
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.sectorsData = response.data;
        this.loading = false;
      });
    },
    getFeatureJobs() {
      axios.get(`${this.apiUrl}/get-feature-jobs`).then((response) => {
        this.featureData = response.data;
      });
    },
    getSpotlightJobs() {
      axios.get(`${this.apiUrl}/get-spotlight-jobs`).then((response) => {
        this.spotlightData = response.data;
      });
    },
    getUrgentJobs() {
      axios.get(`${this.apiUrl}/get-urgent-jobs`).then((response) => {
        this.urgentData = response.data;
      });
    },
    getFeatureCompanies() {
      if (this.token !== null) {
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .get(`${this.apiUrl}/get-feature-companies-auth`)
          .then((response) => {
            this.featureCompaniesData = response.data;
          });
      } else {
        axios.get(`${this.apiUrl}/get-feature-companies`).then((response) => {
          this.featureCompaniesData = response.data;
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100">
      <!-- <div class="container"> -->
      <div
        class="row intro-header"
        style="background: url(images/banner_bg.jpg) no-repeat center center"
      >
        <div class="container intro-message">
          <h1>Pakistan’s Leading Job Website</h1>
          <h1 style="font-weight: lighter">to help you find your Dream Job</h1>
        </div>
      </div>

      <!-- Top Form -->
      <div class="row form-section">
        <div class="container top-form">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <form action="job-listing.html">
              <div class="row">
                <div class="col-md-5 form-group">
                  <label class="labelch7">WHAT</label>
                  <input
                    type="text"
                    class="form-control fieldch selectch"
                    placeholder="Enter Search Words"
                  />
                </div>

                <div class="col-md-5 form-group">
                  <label class="labelch7">WHERE</label>
                  <select class="form-control fieldch">
                    <option selected="" disabled="">Select Location</option>
                    <option>Lahore</option>
                    <option>Karachi</option>
                    <option>Faisalabad</option>
                    <option>Sialkot</option>
                  </select>
                </div>
                <div class="col-md-2 form-group">
                  <div class="pull-right" style="padding-top: 24px">
                    <button type="submit" class="btn btn-default top-form-btn">
                      <i class="fa fa-search icon_s_ch"></i>&nbsp; Search Jobs
                    </button>
                  </div>
                </div>

                <div class="col-md-6 form-group mb-0">
                  <div class="checkboxch">
                    <label
                      ><input type="checkbox" /> Search Job Title Only</label
                    >
                  </div>
                </div>

                <div class="col-sm-12 form-group mb-0">
                  <hr />
                </div>

                <div class="col-sm-12 form-group">
                  <p>
                    <b>Quick Searches:</b>&nbsp;&nbsp;<a
                      class="ch_link_c"
                      href="#"
                      >Jobs By Sectors</a
                    >
                    | <a class="ch_link_c" href="#">Graduate jobs</a> |
                    <a class="ch_link_c" href="#">Apprenticeships</a> |
                    <a class="ch_link_c" href="#">Urgent Jobs</a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--Jobs by sectors-->
      <div class="row">
        <div class="container sector">
          <h1
            class="heading_h_ch align-center"
            style="background: url(images/hr-bg.png) no-repeat center 40px"
          >
            Jobs by sectors
          </h1>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Banking <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Automation <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Engineering <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Marketing <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Goverment <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Nursing <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Health Care <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Administration <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Accounting <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Software & IT <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Creative Design <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <a href="#"
                    >Education <i class="pull-right fa fa-angle-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Spotlight Jobs Across Pakistan-->
      <div
        class="row spotlight"
        style="background: url(images/spotlight_bg.jpg) no-repeat center center"
      >
        <div class="container">
          <h1
            class="heading-inverse align-center"
            style="background: url(images/hr-inverse.png) no-repeat center 48px"
          >
            Spotlight Jobs in Pakistan
          </h1>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-3.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">Network Administrator</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-4.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">Creative Designer</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-1.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">HR Manager</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-4.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">Project Manager</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-1.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">.NET Developer</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-3.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">Oracle Developer</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-3.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">Network Administrator</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-4.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">Creative Designer</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-1.jpg"
                          width="80"
                          height="80"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h4 class="media-heading">HR Manager</h4>
                      <p class="text-primary">Sample Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                      <button type="submit" class="btn btn-default btn-xs">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Featured Jobs-->

      <div class="row">
        <div class="container featured-jobs">
          <h1 class="heading_featured align-center">Featured Jobs</h1>
          <br />

          <div class="col-sm-12">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="well">
                  <div class="media">
                    <div class="media-left">
                      <a href="#"
                        ><img
                          class="media-object img-circle"
                          src="images/company-2.jpg"
                          width="48"
                          height="48"
                          alt="..."
                      /></a>
                    </div>
                    <div class="media-body media-right">
                      <h5 class="media-heading text-primary">
                        <a href="#">UI UX Designer</a>
                      </h5>
                      <p>Company Name</p>
                      <p class="text-muted">Lahore, Karachi</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12"><br /><br /></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <div class="pull-right">
                    <a
                      href="job-listing.html"
                      class="
                        btn btn-default btn-lg
                        outline-btnch
                        Upload_Your_CV
                      "
                      >Load More</a
                    >
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6">
                  <div class="pull-left">
                    <a
                      href="applicants/manage-cv.html"
                      class="btn btn-default btn-lg top-form-btn Upload_Your_CV"
                      >Upload Your CV</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start ch -->
      <div
        class="row join-us"
        style="
          background: url(images/section_bgch.jpg) no-repeat center center;
          margin-bottom: 4em;
        "
      >
        <div class="container join chchch">
          <div class="col-sm-3">
            <h1 class="fontch1">Join Our<br />Employement<br />Network</h1>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2">Apply to Jobs faster even from mobile</h4>
              </div>
            </div>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2">
                  Post a Resume to be found by Registered Empoyers
                </h4>
              </div>
            </div>
          </div>

          <div class="col-sm-3 colch">
            <div class="media">
              <div class="media-left">
                <a href="#"
                  ><img
                    class="media-object"
                    src="images/iconch.png"
                    width="48"
                    height="48"
                    alt="..."
                /></a>
              </div>
              <div class="media-body media-right">
                <h4 class="fontch2 hpch">Get response alert for free</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end ch -->
      <div class="container" style="margin-top: 4%">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h1 class="heading_featured align-center">BROWSE LOCAL JOBS</h1>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Lahore"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >LAHORE</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/punjab.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in Punjab</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Lahore"
                        class="title h6 text-dark"
                        >Jobs in Lahore</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sialkot"
                        class="title h6 text-dark"
                        >Jobs in Sialkot</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gujranwala"
                        class="title h6 text-dark"
                        >Jobs in Gujranwala</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Faisalabad"
                        class="title h6 text-dark"
                        >Jobs in Faisalabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Rawalpindi"
                        class="title h6 text-dark"
                        >Jobs in Rawalpindi</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Karachi"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >KARACHI</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/sindh.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in Sindh</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Karachi"
                        class="title h6 text-dark"
                        >Jobs in Karachi</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Hyderabad"
                        class="title h6 text-dark"
                        >Jobs in Hyderabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sakhar"
                        class="title h6 text-dark"
                        >Jobs in Sakhar</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Dadu"
                        class="title h6 text-dark"
                        >Jobs in Dadu</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Larkana"
                        class="title h6 text-dark"
                        >Jobs in Larkana</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Peshawer"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >PESHAWER</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/kpk.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in KPK</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Peshawer"
                        class="title h6 text-dark"
                        >Jobs in Peshawer</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Abbotabad"
                        class="title h6 text-dark"
                        >Jobs in Abbotabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Swabi"
                        class="title h6 text-dark"
                        >Jobs in Swabi</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Kohat"
                        class="title h6 text-dark"
                        >Jobs in Kohat</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/DI Khan"
                        class="title h6 text-dark"
                        >Jobs in DI Khan</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Quetta"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >QUETTA</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/balochistan.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in Balochistan</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Quetta"
                        class="title h6 text-dark"
                        >Jobs in Quetta</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gawader"
                        class="title h6 text-dark"
                        >Jobs in Gawader</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Ziarat"
                        class="title h6 text-dark"
                        >Jobs in Ziarat</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Chaman"
                        class="title h6 text-dark"
                        >Jobs in Chaman</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Sui"
                        class="title h6 text-dark"
                        >Jobs in Sui</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Mirpur"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >MIRPUR</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/kashmir.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in Kashmir</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Muzafrabad"
                        class="title h6 text-dark"
                        >Jobs in Muzafrabad</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Mirpur"
                        class="title h6 text-dark"
                        >Jobs in Mirpur</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Kotli"
                        class="title h6 text-dark"
                        >Jobs in Kotli</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Bagh"
                        class="title h6 text-dark"
                        >Jobs in Bagh</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Haveli"
                        class="title h6 text-dark"
                        >Jobs in Haveli</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="title h6 text-dark thumbnailch browse_local_jobs_ch">
              <div class="card catagories overflow-hidden rounded border-0">
                <div style="display: flex; justify-content: center">
                  <router-link
                    to="/jobs/Pakistan/Gilgit"
                    class="job_city_title"
                    style="
                      position: absolute;
                      font-size: 24;
                      font-size: 28px;
                      margin-top: 24%;
                      color: white;
                    "
                    >GILGIT</router-link
                  >
                </div>
                <img
                  src="http://historic.job4u.pk/htmlcss/images/baltistan.jpg"
                  class="img-fluid"
                  alt=""
                />

                <div class="card-body">
                  <ul
                    class="list-unstyled d-flex justify-content-between mb-0"
                    style="flex-direction: column"
                  >
                    <li
                      class="h6 mb-0 jobs"
                      style="margin-bottom: 3% !important"
                    >
                      <b>Jobs in Gilgit Baltistan</b>
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Gilgit"
                        class="title h6 text-dark"
                        >Jobs in Gilgit</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Skardu"
                        class="title h6 text-dark"
                        >Jobs in Skardu</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Hunza"
                        class="title h6 text-dark"
                        >Jobs in Hunza</router-link
                      >
                    </li>
                    <li class="h6 mb-0 jobs" style="margin-left: 8%">
                      <router-link
                        to="/jobs/Pakistan/Nagar"
                        class="title h6 text-dark"
                        >Jobs in Nagar</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!-- </div> -->
      <!--end container-->
      <!-- Candidates Start -->
      <div class="container mt-100 mt-17 mb-17">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h1 class="heading_featured align-center">Featured Recruiters</h1>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
            class="col-lg-3 col-md-6 mt-4 pt-2"
            v-for="(item, index) in featureCompaniesData"
            :key="index"
          >
            <div class="card team text-center border-0">
              <div class="card-body job_tiles_ch">
                <div class="position-relative">
                  <img
                    :src="mediaUrl + item.profile_pic"
                    class="img-fluid avatar avatar-ex-large rounded-circle"
                    alt=""
                  />
                </div>
                <div class="content pt-3 pb-3">
                  <h5 class="mb-0">
                    <!-- <a href="javascript:void(0)" class="name text-dark">{{
                      item.company_name
                    }}</a> -->

                    <router-link
                      :to="
                        '/employer/' +
                        item.company_name
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')
                      "
                      class="text-dark company-name"
                      >{{ item && item.company_name }}</router-link
                    >
                  </h5>
                  <small class="designation text-muted">{{
                    item.branches[0].branchAddress
                  }}</small>
                  <ul class="list-unstyled social-icon team-icon mb-0">
                    <li
                      class="list-inline-item"
                      v-if="item.facebook && item.facebook.length > 0"
                    >
                      <a target="_blank" :href="item.facebook" class="rounded">
                        <facebook-icon
                          class="fea icon-sm fea-social"
                        ></facebook-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.instagram && item.instagram.length > 0"
                    >
                      <a target="_blank" :href="item.instagram" class="rounded">
                        <instagram-icon
                          class="fea icon-sm fea-social"
                        ></instagram-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.twitter && item.twitter.length > 0"
                    >
                      <a target="_blank" :href="item.twitter" class="rounded">
                        <twitter-icon
                          class="fea icon-sm fea-social"
                        ></twitter-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.linkedin && item.linkedin.length > 0"
                    >
                      <a target="_blank" :href="item.linkedin" class="rounded">
                        <linkedin-icon
                          class="fea icon-sm fea-social"
                        ></linkedin-icon>
                      </a>
                    </li>

                    <li
                      class="list-inline-item ml-1"
                      v-if="item.subscribe == 1"
                    >
                      <a
                        @click.prevent="subscribe(item)"
                        class="rounded"
                        style="cursor: pointer"
                      >
                        <bell-off-icon
                          class="fea icon-sm fea-social"
                        ></bell-off-icon>
                      </a>
                    </li>
                    <li
                      class="list-inline-item ml-1"
                      v-if="item.subscribe == 0"
                    >
                      <a
                        @click.prevent="subscribe(item)"
                        class="rounded"
                        style="cursor: pointer"
                      >
                        <bell-icon class="fea icon-sm fea-social"></bell-icon>
                      </a>
                    </li>
                  </ul>
                  <!--end icon-->
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--explore more-->

      <div
        class="row explorech"
        style="background: url(images/find-more.png) no-repeat center center"
      >
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="pull-left">
                <h1>
                  We will help to find the Jobs <br />
                  that are right for you
                </h1>
                <p>
                  A better career is out there. We'll help you find it to become
                  <br />
                  everything you want to be.
                </p>
                <br />
                <a
                  href="/recruiter-registeration"
                  class="btn btn-default btn-lg outline-btn-inverse"
                  >Start Now</a
                >
              </div>
            </div>

            <div class="col-sm-6">
              <div class="pull-left" style="padding-left: 60px">
                <h1>Explore Our Recruiters Diretory</h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />Aenean
                  euismod bibendum laoreet.
                </p>
                <br />
                <a
                  href="/recruit-top-talent-in-pakistan "
                  class="btn btn-default btn-lg outline-btn-inverse"
                  >Explore Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Candidates End -->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
